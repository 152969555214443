import * as yup from 'yup';

const CodiceFiscale = require('codice-fiscale-js/dist/codice.fiscale.commonjs2');

// TODO: questo schema non è attualmente usato ma va aggiornata con le regole di yup 1.4.0
export const defaultSchema = yup.object().shape({
  'tf-nome': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup.string().required('Campo richiesto.'),
  }),
  'tf-cognome': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup.string().required('Campo richiesto.'),
  }),
  'tf-nascita': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup
      .string()
      .transform((value) => {
        const dateFormat = /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/[0-9]{4}/;
        if (value.match(dateFormat)) {
          const [day, month, year] = value.split('/');
          return `${year}-${month}-${day}`;
        }
        return value;
      })
      .required('Campo richiesto')
      .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, 'Data non valida.'),
  }),
  crediti: yup
    .string()
    .required('Campo richiesto.')
    .oneOf(
      [
        'crediti-dottori',
        'crediti-consulenti-lavoro',
        'crediti-notai',
        'crediti-avvocati',
        'crediti-tributaristi',
        'crediti-altreprofessioni',
        'no-crediti',
      ],
      'Campo richiesto.',
    ),
  sesso: yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup.string().oneOf(['m', 'f'], 'Campo richiesto.').required('Campo richiesto.'),
  }),
  'tf-provincia': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup.string().required('Campo richiesto.'),
  }),
  'tf-comune': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: yup.string().required('Campo richiesto.'),
  }),
  'tf-cf-confirm': yup.boolean().notRequired(),
  'tf-cf': yup
    .string()
    .when(
      ['crediti', 'tf-nome', 'tf-cognome', 'sesso', 'tf-nascita', 'tf-comune', 'tf-cf-confirm'],
      (crediti, nome, cognome, sesso, nascita, comune, confirm) => {
        // se crediti è uguale a no-crediti, ritorno non richiesto
        if (crediti === 'no-crediti') {
          return yup.string().notRequired();
        }
        // se non ho tutti i campi per controllare il cf, valido solo la stringa
        const hasUndefinedValues = [nome, cognome, nascita, sesso, comune].some(
          (field) => field == null || !field.length,
        );
        if (hasUndefinedValues) {
          return yup
            .string()
            .test('is-valid-cf-string', 'Formato del codice fiscale errato.', (value) =>
              value === '' ? true : CodiceFiscale.check(value),
            )
            .required('Campo richiesto.');
        }
        if (confirm) {
          return yup.string().required('Campo richiesto.');
        }
        // controllo il codice fiscale
        return yup
          .string()
          .test('is-valid-cf', 'il codice fiscale non corrisponde ai dati anagrafici.', (value) => {
            const codice = new CodiceFiscale({
              name: nome,
              surname: cognome,
              gender: sesso,
              birthday: nascita,
              birthplace: comune,
            });
            return value.toUpperCase() === codice.toString();
          })
          .required('Campo richiesto.');
      },
    ),
  'tf-collegio': yup.string().when('crediti', {
    is: 'crediti-dottori',
    then: yup.string().required('Campo richiesto.'),
  }),
  'tf-tesserino-dui': yup
    .number()
    .positive('Numero tesserino non valido.')
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .when('crediti', {
      is: 'crediti-consulenti-lavoro',
      then: (schema) =>
        schema
          .positive('Numero tesserino non valido.')
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .required('Campo richiesto.'),
    }),
  'tf-associazione': yup.string().when('crediti', {
    is: 'crediti-tributaristi',
    then: (schema) =>
      schema
        .oneOf(['ANCIT', 'ANCOT', 'LAIT', 'LAPET', 'INT', 'CON.FI.T.I'], 'Campo Richiesto.')
        .required('Campo Richiesto.'),
  }),
  'tf-numero-iscrizione': yup
    .number()
    .positive('Numero iscrizione non valido.')
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .when('crediti', {
      is: 'crediti-tributaristi',
      then: (schema) =>
        schema
          .positive('Numero iscrizione non valido.')
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .required('Campo Richiesto.'),
    }),
});

export const tf2021Schema = yup.object().shape({
  'tf-nome': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-cognome': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-nascita': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) =>
      schema
        .transform((value) => {
          const dateFormat = /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/[0-9]{4}/;
          if (value.match(dateFormat)) {
            const [day, month, year] = value.split('/');
            return `${year}-${month}-${day}`;
          }
          return value;
        })
        .required('Campo richiesto')
        .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, 'Data non valida.'),
  }),
  crediti: yup
    .string()
    .required('Campo richiesto.')
    .oneOf(
      [
        'crediti-dottori',
        'crediti-consulenti-lavoro-tfgiugno',
        'crediti-consulenti-lavoro-tf2022',
        'crediti-notai',
        'crediti-avvocati',
        'crediti-tributaristi',
        'crediti-altreprofessioni',
        'crediti-giornalisti',
        'no-crediti',
      ],
      'Campo richiesto.',
    ),
  sesso: yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) => schema.oneOf(['m', 'f'], 'Campo richiesto.').required('Campo richiesto.'),
  }),
  'tf-provincia': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-comune': yup.string().when('crediti', {
    is: (val) => val !== 'no-crediti',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-cf-confirm': yup.boolean().notRequired(),
  'tf-cf': yup
    .string()
    .when(
      ['crediti', 'tf-nome', 'tf-cognome', 'sesso', 'tf-nascita', 'tf-comune', 'tf-cf-confirm'],
      ([crediti, nome, cognome, sesso, nascita, comune, confirm], schema) => {
        // se crediti è uguale a no-crediti, ritorno non richiesto
        if (crediti === 'no-crediti') {
          return schema.notRequired();
        }
        // se non ho tutti i campi per controllare il cf, valido solo la stringa
        const hasUndefinedValues = [nome, cognome, nascita, sesso, comune].some(
          (field) => field == null || !field.length,
        );
        if (hasUndefinedValues) {
          return schema
            .test('is-valid-cf-string', 'Formato del codice fiscale errato.', (value) =>
              value === '' ? true : CodiceFiscale.check(value),
            )
            .required('Campo richiesto.');
        }
        if (confirm) {
          return schema.required('Campo richiesto.');
        }
        // controllo il codice fiscale
        return schema
          .test('is-valid-cf', 'il codice fiscale non corrisponde ai dati anagrafici.', (value) => {
            const codice = new CodiceFiscale({
              name: nome,
              surname: cognome,
              gender: sesso,
              birthday: nascita,
              birthplace: comune,
            });
            return value.toUpperCase() === codice.toString();
          })
          .required('Campo richiesto.');
      },
    ),
  'tf-collegio': yup.string().when('crediti', {
    is: (value) => value === 'crediti-dottori' || value === 'crediti-giornalisti',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-consulenti-iscrizione-albo': yup
    .number()
    .positive('Numero iscrizione non valido.')
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .when('crediti', {
      is: (value) =>
        value === 'crediti-consulenti-lavoro-tfgiugno' ||
        value === 'crediti-consulenti-lavoro-tf2022',
      then: (schema) =>
        schema
          .positive('Numero iscrizione non valido.')
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .required('Campo richiesto.'),
    }),
  'tf-consulenti-provincia': yup.string().when('crediti', {
    is: (value) =>
      value === 'crediti-consulenti-lavoro-tfgiugno' ||
      value === 'crediti-consulenti-lavoro-tf2022',
    then: (schema) => schema.required('Campo richiesto.'),
  }),
  'tf-associazione': yup.string().when('crediti', {
    is: 'crediti-tributaristi',
    then: (schema) =>
      schema
        .oneOf(['ANCIT', 'ANCOT', 'LAIT', 'LAPET', 'INT', 'CON.FI.T.I'], 'Campo Richiesto.')
        .required('Campo Richiesto.'),
  }),
  'tf-numero-iscrizione': yup
    .number()
    .positive('Numero iscrizione non valido.')
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .when('crediti', {
      is: 'crediti-tributaristi',
      then: (schema) =>
        schema
          .positive('Numero iscrizione non valido.')
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .required('Campo Richiesto.'),
    }),
});
