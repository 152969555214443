import { defaultSchema, tf2021Schema } from './validationSchemas';
/**
 * NB!!! Ciascuna chiave dell'oggetto config deve
 coincidere con il pathname della pagina.
 *
 * Descrizione parametri:
 *
 * loginUrl: Url della pagina di login, il parametro viene usato in FormController per gestire i redirect.
 * Va SEMPRE aggiunto il punto interrogativo finale (saranno accodati dei parametri nella query string)
 *
 * formUrl: Url del form di registrazione, può essere formPay.html o form.html
 *
 * primoroundBaseUrl: Url di primo round, tutte le url vanno specificate anche nel file env
 *
 * from: Parametro query string inserito nel redirect dalla pagina login a quella di registrazione. Viene usata
 * per gestire il ritorno alla pagina di login dal form di registrazione una volta fatto il submit
 *
 * blacklistCategories: elenco delle categorie professionali da nascondere nel form di registrazione.
 */
const configs = {
  '/registrazionePlus.html': {
    isLive: false,
    loginUrl: 'registrazionePlus.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2022-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2022-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2022-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2022-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2022-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH_2021,
    primoroundBaseUrl: process.env.PRIMOROUND_URL,
    // Commentare la variabile thankYouPath nel passaggio alla differita
    // thankYouPath: process.env.STREAMING_URL,
    from: 'regPlus',
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: ['crediti-avvocati', 'crediti-notai', 'crediti-altreprofessioni'],
    lockDifferita: true,
  },
  '/webinar-001.html': {
    loginUrl: 'webinar-001.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2022-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2022-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2022-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2022-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2022-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web01',
    primoroundBaseUrl: process.env.WEBINAR_01_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: ['crediti-avvocati', 'crediti-notai', 'crediti-altreprofessioni'],
  },
  '/webinar-002.html': {
    loginUrl: 'webinar-002.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2022-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2022-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2022-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2022-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2022-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web02',
    primoroundBaseUrl: process.env.WEBINAR_02_PRIMOROUND_URL,
    useHashpEndpoint: true,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: ['crediti-avvocati', 'crediti-notai', 'crediti-altreprofessioni'],
  },
  '/webinar-003.html': {
    loginUrl: 'webinar-003.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2022-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2022-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2022-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2022-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2022-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web03',
    primoroundBaseUrl: process.env.WEBINAR_03_PRIMOROUND_URL,
    useHashpEndpoint: true,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: ['crediti-avvocati', 'crediti-notai', 'crediti-altreprofessioni'],
  },
  '/webinarPlus.html': {
    loginUrl: 'webinarPlus.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2022-${process.env.STAGE}/signup`,
    profileUrl: `Tf2022-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2022-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2022-${process.env.STAGE}/check`,
    hashUrl: `Tf2022-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'webPlus',
    primoroundBaseUrl: process.env.WEBCAST_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    lockDifferita: true,
    blacklistCategories: ['crediti-altreprofessioni', 'crediti-notai', 'crediti-avvocati'],
  },
  '/webinar-004.html': {
    loginUrl: 'webinar-004.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Bonus, 110% e aiuti per ripartire – Speciale Telefisco: la differita',
    from: 'web04',
    primoroundBaseUrl: process.env.WEBINAR_04_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="https://ecommerce-static.ilsole24ore.com/landing/speciale-telefisco/">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-005.html': {
    loginUrl: 'webinar-005.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Il bonus 110%: gli approfondimenti',
    from: 'web05',
    primoroundBaseUrl: process.env.WEBINAR_05_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="https://ecommerce-static.ilsole24ore.com/landing/speciale-telefisco/">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-006.html': {
    loginUrl: 'webinar-006.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Gli aiuti alle imprese',
    from: 'web06',
    primoroundBaseUrl: process.env.WEBINAR_06_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="https://ecommerce-static.ilsole24ore.com/landing/speciale-telefisco/">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-007.html': {
    loginUrl: 'webinar-007.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Gli ultimi controlli sulle dichiarazioni dei redditi',
    from: 'web07',
    primoroundBaseUrl: process.env.WEBINAR_07_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="https://ecommerce-static.ilsole24ore.com/landing/speciale-telefisco/">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-008.html': {
    // Evento 14 Giugno 2021
    loginUrl: 'webinar-008.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web08',
    primoroundBaseUrl: process.env.WEBINAR_08_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-009.html': {
    // Evento 12 Luglio 2021
    loginUrl: 'webinar-009.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web09',
    primoroundBaseUrl: process.env.WEBINAR_09_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-010.html': {
    // Evento 16 Settembre 2021
    loginUrl: 'webinar-010.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web10',
    primoroundBaseUrl: process.env.WEBINAR_10_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-011.html': {
    // Evento 14 Ottobre 2021
    loginUrl: 'webinar-011.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web11',
    primoroundBaseUrl: process.env.WEBINAR_11_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-012.html': {
    // Evento 18 Novembre 2021
    loginUrl: 'webinar-012.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web12',
    primoroundBaseUrl: process.env.WEBINAR_12_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/index.html': {
    // Evento Telefisco 2024 gennaio
    isLive: process.env.IS_LIVE === 'true' || false,
    loginUrl: 'index.html?',
    formUrl: 'form.html',
    signupUrl: `Tf2024-Settembre-${process.env.STAGE}/signupfree`,
    profileUrl: `Tf2024-Settembre-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2024-Settembre-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2024-Settembre-${process.env.STAGE}/check`,
    hashUrl: `Tf2024-Settembre-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH_2021,
    thankYouPath:
      process.env.IS_LIVE === 'true' ? process.env.STREAMING_URL : process.env.THANK_YOU_PATH,
    from: 'tf2024',
    primoroundBaseUrl: process.env.WEBINAR_12_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [],
    errorText: `Le iscrizioni a Speciale Telefisco 2024 sono chiuse. <br>
    La differita dell’evento del 19 settembre sarà disponibile in <a href="https://ecommerce-static.ilsole24ore.com/landing/speciale-telefisco/">Telefisco Advanced</a> a partire dal 23 settembre.<br><br>
    Telefisco Advanced oltre alla differita dell’evento del 19 settembre offrirà anche la possibilità di seguire le 12 sessioni formative di Master Telefisco degli ultimi 3 mesi dell’anno (con un incontro alla settimana della durata di 2 ore, visionabile anche in differita a partire dal 2 ottobre).<br><br>Questi importanti appuntamenti settimanali  consentiranno di costruire una linea di continuità con gli argomenti trattati nella diretta di Speciale Telefisco, dando l'opportunità di approfondire ulteriormente con i nostri Esperti le novità più rilevanti della fine del 2024.`,
  },
};

export default configs;
